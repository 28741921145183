import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Analytics API v1 is now available. Almost all the data obtained by Nucleus can be retrieved using this API.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This API is in beta for now, please let us know if you encounter any issue or if you need to fetch a specific data that isn't documented here, we strive to be fast to respond. We will add more endpoints over time.`}</p>
    </blockquote>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`Nucleus expects the API key to be included in all requests to the server in a auth header:`}</p>
    <p><inlineCode parentName="p">{`Authorization: your_access_token`}</inlineCode></p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl "api_endpoint_here"
    -H "Authorization: your_access_token"
`}</code></pre>
    <p>{`You may also send it in the body of a POST request as the parameter `}<inlineCode parentName="p">{`token`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "quick-glance"
    }}>{`Quick Glance`}</h2>
    <p>{`Fetch the number of users, installs, sessions and errors during the last 24 hours (and during the previous 24h period for comparison).`}</p>
    <h4 {...{
      "id": "http-request"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://app.nucleus.sh/api/v1/apps/:appId/analytics/quickglance`}</inlineCode></p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl "https://app.nucleus.sh/api/v1/apps/:appId/analytics/quickglance"
    -H "Authorization: your_access_token"
`}</code></pre>
    <p>{`It will return the numbers like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "installs": 35,
    "users": 15,
    "appStarts": 205,
    "errors": 526,
    "previousInstalls": 26,
    "previousUsers": 21,
    "previousAppStarts": 46,
    "previousErrors": 256
  }
}
`}</code></pre>
    <h2 {...{
      "id": "daily-analytics"
    }}>{`Daily Analytics`}</h2>
    <p>{`The analytics data of your application grouped by day.`}</p>
    <p>{`You need to supply a date interval as timestamps.`}</p>
    <h4 {...{
      "id": "http-request-1"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://app.nucleus.sh/api/v1/apps/:appId/analytics`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Optional`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Timestamp for beginning of interval`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Timestamp for end of interval`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`utcOffset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Your timezone UTC offset (`}<strong parentName="td">{`in minutes`}</strong>{`) so we can return the appropriate dates`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl "https://app.nucleus.sh/api/v1/apps/:appId/analytics?start=1618010248&end=1618211950&utcOffset=480"
    -H "Authorization: your_access_token"
`}</code></pre>
    <p>{`It will return various data on your app like the following.
Where each object contains a key/value pair for every day in the period you supplied.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "totalNumbers": {
      "users": 89,
      "installs": 48
    },
    "usage": {
      "2021-04-09": 8,
      "2021-04-10": 25,
      "2021-04-11": 65
    },
    "newUsers": {
      "2021-04-09": 6,
      "2021-04-10": 15,
      "2021-04-11": 25
    },
    "activeUsers": {
      "2021-04-09": 5,
      "2021-04-10": 12,
      "2021-04-11": 21
    },
    "nonNewUsers": {
      "2021-04-09": -1,
      "2021-04-10": 3,
      "2021-04-11": -3
    },
    "hours": {
      "2021-04-09": [1, 1, 2],
      "2021-04-10": [2, 1, 1, 1],
      "2021-04-11": [1, 1, 1, 1, 2, 2, 3, 3]
    },
    "platforms": [
      { "value": "linux", "count": 12 },
      { "value": "mac", "count": 8 },
      { "value": "win", "count": 17 }
    ],
    "ram": [],
    "languages": [
      { "value": "en", "count": 15 },
      { "value": "fr", "count": 8 }
    ],
    "versions": [{ "value": "0.2.1", "count": 55 }],
    "countries": [
      { "value": "BG", "count": 6 },
      { "value": "US", "count": 20 }
    ],
    "avgSessionDuration": {
      "2021-04-10": 1789,
      "2021-04-11": 2589.5
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "events"
    }}>{`Events`}</h2>
    <p>{`The daily stats of your application's events.`}</p>
    <p>{`You need to supply a date interval as timestamps.`}</p>
    <h4 {...{
      "id": "http-request-2"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://app.nucleus.sh/api/v1/apps/:appId/analytics/events`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Optional`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Timestamp for beginning of interval`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Timestamp for end of interval`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`utcOffset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Your timezone UTC offset (`}<strong parentName="td">{`in minutes`}</strong>{`) so we can return the appropriate dates`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl "https://app.nucleus.sh/api/v1/apps/:appId/analytics/events?start=1618010248&end=1618211950&utcOffset=480"
    -H "Authorization: your_access_token"
`}</code></pre>
    <p>{`It will return details about custom events that you have set in your app tracking.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "ITEM_PLAYED": {
      "2021-04-11": 256,
      "2021-04-10": 152
    },
    "ITEM_SAVED": {
      "2021-04-11": 314,
      "2021-04-10": 124
    },
    "ITEM_CHECKOUT": {
      "2021-04-11": 289,
      "2021-04-10": 325
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "events-properties"
    }}>{`Events Properties`}</h2>
    <p>{`Get details on the custom data reported alongside your events.`}</p>
    <p>{`You need to supply a date interval as timestamps.`}</p>
    <h4 {...{
      "id": "http-request-3"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://app.nucleus.sh/api/v1/apps/:appId/analytics/eventsprops`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Optional`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Timestamp for beginning of interval`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Timestamp for end of interval`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`utcOffset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Your timezone UTC offset (`}<strong parentName="td">{`in minutes`}</strong>{`) so we can return the appropriate dates`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl "https://app.nucleus.sh/api/v1/apps/:appId/analytics/eventsprops?start=1618010248&end=1618211950&utcOffset=480"
    -H "Authorization: your_access_token"
`}</code></pre>
    <p>{`It will return a list of events and attributes (custom data you reported) like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": [
    {
      "event": "init",
      "keys": ["plan", "displayName"],
      "type": "string"
    }
  ]
}
`}</code></pre>
    <h2 {...{
      "id": "event-attributes"
    }}>{`Event Attributes`}</h2>
    <p>{`This will fetch data about a specific event and its attribute.`}</p>
    <p>{`You need to supply a date interval as timestamps.`}</p>
    <h4 {...{
      "id": "http-request-4"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://app.nucleus.sh/api/v1/apps/:appId/analytics/events/:event/:attr`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Optional`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Timestamp for beginning of interval`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Timestamp for end of interval`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`utcOffset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Your timezone UTC offset (`}<strong parentName="td">{`in minutes`}</strong>{`) so we can return the appropriate dates`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl "https://app.nucleus.sh/api/v1/apps/appId/analytics/events/init/plan?start=1617358132&end=1617962932&utcOffset=480"
    -H "Authorization: your_access_token"
`}</code></pre>
    <p>{`It will return data for a specific event and event attribute that is requested`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": [
    { "value": "pro", "count": 35 },
    { "value": "free", "count": 24 }
  ]
}
`}</code></pre>
    <h2 {...{
      "id": "errors"
    }}>{`Errors`}</h2>
    <p>{`This is your application's error records.`}</p>
    <p>{`You need to supply a date interval as timestamps.`}</p>
    <h4 {...{
      "id": "http-request-5"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://app.nucleus.sh/api/v1/apps/:appId/analytics/errors`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Optional`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Timestamp for beginning of interval`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Timestamp for end of interval`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`utcOffset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Your timezone UTC offset (`}<strong parentName="td">{`in minutes`}</strong>{`) so we can return the appropriate dates`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl "https://app.nucleus.sh/api/v1/apps/:appId/analytics/errors?start=1618010248&end=1618211950&utcOffset=480"
    -H "Authorization: your_access_token"
`}</code></pre>
    <p>{`It will return the data for all `}<inlineCode parentName="p">{`uncaughtException`}</inlineCode>{` and `}<inlineCode parentName="p">{`unhandledRejection`}</inlineCode>{` reports as well as any custom error reports that you have set.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "unhandledRejection": {
      "2021-04-09": 52,
      "2021-04-10": 28,
      "2021-04-11": 75
    },
    "uncaughtException": {
      "2021-04-09": 26,
      "2021-04-10": 37,
      "2021-04-11": 69
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "users"
    }}>{`Users`}</h2>
    <p>{`The Users data for your application.`}</p>
    <h4 {...{
      "id": "http-request-6"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://app.nucleus.sh/api/v1/apps/:appId/users`}</inlineCode></p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl "https://app.nucleus.sh/api/v1/apps/:appId/users"
    -H "Authorization: your_access_token"
`}</code></pre>
    <p>{`It will return all users data`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "total": 1,
  "data": [
    {
      "_id": "xxxxxxxxxx",
      "userId": "test_user",
      "createdAt": "2021-03-23T06:51:38.633Z",
      "devices": [
        {
          "lastSeen": "2021-03-23T07:42:47.379Z",
          "_id": "xxxxxxxxxx",
          "platform": "win",
          "osVersion": "6.3.9600",
          "version": "0.0.0"
        },
        {
          "lastSeen": "2021-03-23T08:23:44.497Z",
          "_id": "xxxxxxxxxx",
          "platform": "win",
          "osVersion": "8.1",
          "version": "0.0.0"
        }
      ],
      "lastSeen": "2021-03-23T08:23:44.497Z",
      "props": {
        "country": "US",
        "locale": "en"
      }
    }
  ]
}
`}</code></pre>
    <h2 {...{
      "id": "user-details"
    }}>{`User Details`}</h2>
    <p>{`This shows data for a specific user.`}</p>
    <h4 {...{
      "id": "http-request-7"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://app.nucleus.sh/api/v1/apps/:appId/users/:userId`}</inlineCode></p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl "https://app.nucleus.sh/api/v1/apps/:appId/users/:userId"
    -H "Authorization: your_access_token"
`}</code></pre>
    <p>{`It will return the user details and all its sessions`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "user": {
    "lastSeen": "2021-03-23T08:23:44.497Z",
    "_id": "xxxxxxxxx",
    "appId": "xxxxxxxxxxxxxxxxxxxxx",
    "userId": "test_user",
    "__v": 0,
    "createdAt": "2021-03-23T06:51:38.633Z",
    "devices": [
      {
        "lastSeen": "2021-03-23T07:42:47.379Z",
        "_id": "xxxxxxxxx",
        "platform": "win",
        "osVersion": "6.3.9600",
        "version": "0.0.0"
      },
      {
        "lastSeen": "2021-03-23T08:23:44.497Z",
        "_id": "xxxxxxxxx",
        "platform": "win",
        "osVersion": "8.1",
        "version": "0.0.0"
      }
    ],
    "props": {
      "country": "US",
      "locale": "en"
    },
    "updatedAt": "2021-03-23T08:23:44.497Z"
  },
  "sessions": [
    {
      "device": "xxxxxxxxx",
      "actions": 1,
      "errors": 0,
      "end": "2021-03-23T07:41:38.479Z",
      "start": "2021-03-23T07:41:38.479Z",
      "session": "xxxxxx"
    },
    {
      "device": "xxxxxxxxx",
      "actions": 1,
      "errors": 0,
      "end": "2021-03-23T06:51:39.980Z",
      "start": "2021-03-23T06:51:39.980Z",
      "session": "xxxxxx"
    }
  ]
}
`}</code></pre>
    <h2 {...{
      "id": "user-session-events"
    }}>{`User Session Events`}</h2>
    <p>{`This will fetch events data for a specific user session.`}</p>
    <p>{`You need to supply a device Id in the request parameters, that you can obtain in the previous User Details call.`}</p>
    <h4 {...{
      "id": "http-request-8"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://app.nucleus.sh/api/v1/apps/:appId/users/:userId/session/:sessionId`}</inlineCode></p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl "https://app.nucleus.sh/api/v1/apps/:appId/users/:userId/session/:sessionId?device=:deviceId"
    -H "Authorization: your_access_token"
`}</code></pre>
    <p>{`It will return the list of events of a session made by a specific user`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": [
    {
      "app_id": "xxxxxxxxxxxxxxxxxxxxxxxx",
      "platform": "win",
      "user_id": "test_user",
      "machine_id": "xxxxxxxxxx",
      "version": "0.0.0",
      "locale": "en",
      "renderer": null,
      "module_version": "2.6.0",
      "avail_ram": 4,
      "type": "init",
      "time": "2021-03-23T09:31:49.647Z",
      "country": "US",
      "arch": "x64",
      "first_time": false,
      "session_id": "xxxx",
      "error_hash": null,
      "os_version": "8.1",
      "data": {
        "plan": "pro"
      },
      "duration": null
    }
  ]
}
`}</code></pre>
    <h2 {...{
      "id": "delete-user"
    }}>{`Delete User`}</h2>
    <p>{`This will delete a specific user (by id) and it's associated data.`}</p>
    <h4 {...{
      "id": "http-request-9"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`DELETE https://app.nucleus.sh/api/v1/apps/:appId/users/:userId`}</inlineCode></p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl -X DELETE "https://app.nucleus.sh/api/v1/apps/:appId/users/:userId"
    -H "Authorization: your_access_token"
`}</code></pre>
    <p>{`It will return a 204 code if the user deletion is successful.`}</p>
    <h2 {...{
      "id": "live-events"
    }}>{`Live Events`}</h2>
    <p>{`This shows Live events data.`}</p>
    <h4 {...{
      "id": "http-request-10"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://app.nucleus.sh/api/v1/apps/:appId/live/events`}</inlineCode></p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl "https://app.nucleus.sh/api/v1/apps/:appId/live/events"
    -H "Authorization: your_access_token"
`}</code></pre>
    <p>{`It will return the list of live events in your app`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": [
    {
      "app_id": "xxxxxxxxxxxxxxxxxxxxxxxx",
      "platform": "mac",
      "user_id": "test_user",
      "machine_id": "xxxxxxxxx",
      "version": "0.0.0",
      "locale": "en",
      "renderer": null,
      "module_version": "2.6.0",
      "avail_ram": 4,
      "type": "ITEM_PLAYED",
      "time": "2021-04-12T10:05:05.673Z",
      "country": "US",
      "arch": "x64",
      "first_time": false,
      "session_id": "xxxxxx",
      "error_hash": null,
      "os_version": "8.1",
      "data": {
        "installerType": "MACOS-INSTALLER"
      },
      "duration": null
    }
  ]
}
`}</code></pre>
    <h2 {...{
      "id": "live-users-count"
    }}>{`Live Users Count`}</h2>
    <p>{`Get the real-time count of how many users are using your app`}</p>
    <h4 {...{
      "id": "http-request-11"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://app.nucleus.sh/api/v1/apps/:appId/live/users/count`}</inlineCode></p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl "https://app.nucleus.sh/api/v1/apps/:appId/live/users/count"
    -H "Authorization: your_access_token"
`}</code></pre>
    <p>{`It will return total live users data`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": 234
}
`}</code></pre>
    <h2 {...{
      "id": "live-users-list"
    }}>{`Live Users list`}</h2>
    <p>{`Get a list of all the users on your app, right now.`}</p>
    <h4 {...{
      "id": "http-request-12"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://app.nucleus.sh/api/v1/apps/:appId/live/users`}</inlineCode></p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl "https://app.nucleus.sh/api/v1/apps/:appId/live/users"
    -H "Authorization: your_access_token"
`}</code></pre>
    <p>{`It will return list of all live users and details such as platform and country`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": [
    {
      "userDbId": "xxxxxxxxx",
      "userId": "xxxxxxxxx",
      "deviceId": "xxxxxxxxx",
      "platform": "win",
      "osVersion": "8.1",
      "country": "US",
      "lastSeen": "2021-04-12T10:26:08.867Z"
    }
  ]
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      